import React from "react";
import css from './allNewsList.module.scss';
import {viewports} from "../../../../componentsUtils";
import Img from 'gatsby-image';
import NewsBasicTemplate from "../../../../shared/NewsBasicTemplate/NewsBasicTemplate";
import useBreakpoint from "../../../../hooks/useBreakpoint";
import Link from 'gatsby-link'
import NewsPaginationButton from "../../../../shared/NewsPaginationButton/NewsPaginationButton";
import ArrowLeft from "../../../../../images/icons/arrow-left.svg";
import ArrowsLeft from "../../../../../images/icons/arrows-left.svg";
import ArrowRight from "../../../../../images/icons/arrow-right.svg";
import ArrowsRight from "../../../../../images/icons/arrows-right.svg";
import PageTitle from "../../../../shared/PageTitle/PageTitle";

const AllNewsList = props => {
    const breakpoint = useBreakpoint();
    const {index, pageCount, pathPrefix} = props.pageContext || {};
    const {newsMarkdownTemplateFile} = props.allNewsDetails.site.siteMetadata;
    const posts = props.allNewsDetails.allMarkdownRemark.edges.filter(post => post.node.fields.slug !== newsMarkdownTemplateFile);

    const getLink = (externalLink, internalLink) => {
        if (!!externalLink) {
            return externalLink;
        }
        return internalLink;
    };

    const getImageMovieContent = frontmatter => {
        const content = {data: null, type: null};
        if (frontmatter.image) {
            const image = (frontmatter.image.childImageSharp && frontmatter.image.childImageSharp.fluid) || "";
            content.data = image && <Img fluid={image} alt={frontmatter.imageDescription}/>
            content.type = 'image';
        } else if (frontmatter.youtube) {
            const src = `https://www.youtube.com/embed/${frontmatter.youtube}`;
            content.data = (
                <iframe
                    title={src}
                    width="100%"
                    src={src}
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                />
            );
            content.type = 'youtube';
        }
        return content;
    };

    const mapPosts = () => {
        return posts.map((post, index) => {
            const {node = {}} = post;
            const {frontmatter = {}, fields = {}} = node;
            const slug = fields.slug;
            const {
                copyright = '',
                tags = '',
                externalLink = '',
                date = ''
            } = frontmatter;

            const title = node.frontmatter.title || slug;
            const newLink = getLink(externalLink, slug);
            const content = getImageMovieContent(frontmatter);
            const key = slug.replace(/\//g, '_').replace(/-/g, '_');

            return (
                <div key={key} id={key}>
                    <NewsBasicTemplate
                        external={!!externalLink}
                        content={content.data}
                        contentType={content.type}
                        copyright={copyright}
                        title={title}
                        link={newLink}
                        tags={tags}
                        date={date}
                    />
                </div>
            );
        })
    };

    const renderPaginationCurrentLink = (key, label) => {
        return (
            <div key={key} className={css.paginationItem}>
                <NewsPaginationButton label={label}/>
            </div>
        )
    };

    const renderPaginationActiveLink = (key, label, to) => {
        return (
            <Link key={key} to={to} className={[css.paginationItem, css.paginationItemLink].join(' ')}>
                <NewsPaginationButton label={label} filled/>
            </Link>
        )
    };

    const renderPaginationInactiveLink = (key, label) => {
        return (
            <div className={css.paginationItem} key={key}>
                <NewsPaginationButton label={label} filled disabled />
            </div>
        )
    };

    const renderPagination = () => {
        // by https://codereview.stackexchange.com/a/183461
        const maxRange = (breakpoint.viewport === viewports.phoneViewport) ? 3 : 5;
        const range = (pageCount <= maxRange) ? pageCount : maxRange;
        const pages = pageCount;
        const current = index;
        const start = 1;
        const paging = [];
        let i = Math.min(pages + start - range, Math.max(start, current - (range / 2 | 0)));
        const end = i + range;
        while (i < end) {
            const label = i;
            const key = `news_pagination_${label}`;
            if (i === current) {
                paging.push(renderPaginationCurrentLink(key, label))
            } else {
                const to = (i === 1) ? `/${pathPrefix}/` : `/${pathPrefix}/${i}/`;
                paging.push(renderPaginationActiveLink(key, label, to));
            }
            i++;
        }

        const arrLeft = <img src={ArrowLeft} alt={""} className={css.paginationArrow}/>;
        const arrDoubleLeft = <img src={ArrowsLeft} alt={""} className={css.paginationArrow}/>;

        const arrRight = <img src={ArrowRight} alt={""} className={css.paginationArrow}/>;
        const arrDoubleRight = <img src={ArrowsRight} alt={""} className={css.paginationArrow}/>;

        // button Previous
        if (current === 1) {
            paging.unshift(renderPaginationInactiveLink('previous', arrLeft));
        } else if (current === 2) {
            paging.unshift(renderPaginationActiveLink('previous', arrLeft, `/${pathPrefix}/`));
        } else {
            paging.unshift(renderPaginationActiveLink('previous', arrLeft, `/${pathPrefix}/${current - 1}/`));
        }

        // button Next
        if (current === pages) {
            paging.push(renderPaginationInactiveLink('next', arrRight));
        } else {
            paging.push(renderPaginationActiveLink('next', arrRight, `/${pathPrefix}/${current + 1}/`));
        }

        // button First
        if (current === 1) {
            paging.unshift(renderPaginationInactiveLink('first', arrDoubleLeft));
        } else {
            paging.unshift(renderPaginationActiveLink('first', arrDoubleLeft, `/${pathPrefix}/`));
        }

        // button Last
        if (current === pages) {
            paging.push(renderPaginationInactiveLink('last', arrDoubleRight));
        } else {
            paging.push(renderPaginationActiveLink('last', arrDoubleRight, `/${pathPrefix}/${pages}/`));
        }

        return paging;
    };

    return (
        <div className={css.wrapper}>
            <div className={css.header}>
                <PageTitle>News</PageTitle>
                <div className={css.topPaginationWrapper}>
                    <div className={css.pagination1}>
                        {renderPagination()}
                    </div>
                </div>
            </div>
            <div className={css.wrapperInner}>
                <div className={css.posts}>
                    {mapPosts()}
                </div>
                <div className={css.pagination}>
                    {renderPagination()}
                </div>
            </div>
        </div>

    )
};

export default AllNewsList;
